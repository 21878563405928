.--horizontal {
  &-align {
    &--left {
      &, .mdc-button__label {
        &, span {
          display: flex !important;
          justify-content: flex-start !important;
        }
      }
    }

    &--right {
      &, .mdc-button__label {
        &, span {
          display: flex !important;
          justify-content: flex-end !important;
        }
      }
    }
  }
}
