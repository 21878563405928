$colors: (
  // primaries
        primary: (#DC2975, #FFFFFF),
        secondary: (#012E45, #FFFFFF),
        light: (#f3f3f3, #333333),
        dark: (#2f2f2f, #FFFFFF),
        dark-blue: (#274D61, #FFFFFF),
  // behavior
        success: (#79C651, #FFFFFF),
        danger: (#ce2100, #FFFFFF),
        warning: (#ffc107, #111111),
        info: (#007AFF, #FFFFFF),
        muted: (#cccccc, #333333),
        white: (#ffffff, #333333),
        black: (#000000, #ffffff),
        disabled: (#b5b5b5, #e4e4e4),
  // theme
        photo: (#237bb9, #FFFFFF),
        single: (#ffffff, #333333),
        sequence: (#fc627d, #FFFFFF),
        challenge: (#FCB314, #FFFFFF),
        mirror: (#a70048, #FFFFFF),
  // social
        facebook: (#4267B2, #FFFFFF),
        instagram: (#E1306C, #FFFFFF),
        twitter: (#1DA1F2, #FFFFFF),
        youtube: (#FF0000, #FFFFFF),
        whatsapp: (#128c7e, #FFFFFF),
        google: (#4285f4, #FFFFFF),
        gotchosen: (#145fab, #FFFFFF),
  //colors
        gray: (#EEE, #333333)
);

@function getMipColor($colorName, $backgroundOrText: 1, $lightness: false) {
  $color: nth(map-get($colors, $colorName), $backgroundOrText);

  @if ($lightness) {
    @return scale-color($color, $lightness: $lightness);
  } @else {
    @return $color;
  }
}

$directions: '', top, right, bottom, left;

@mixin getBorder($color, $direction: '', $style: solid) {
  @if $direction == '' {
    border: 1px $style $color !important;
  } @else {
    border-#{$direction}: 1px $style $color !important;
  }
}

// mixin to change img[src="*.svg"] color
@mixin recolor($color: #000, $opacity: 1) {
  $r: calc(red($color) / 255);
  $g: calc(green($color) / 255);
  $b: calc(blue($color) / 255);
  $a: $opacity;

  // grayscale fallback if SVG from data url is not supported
  $lightness: lightness($color);
  filter: saturate(10%) brightness(0%) invert($lightness) opacity($opacity);

  // color filter
  $svg-filter-id: "recolor";
  filter: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}');
}

@mixin renderMipColor($darkBackground: false) {

  .mip-color {

    &--bg-transparent {
      background-color: transparent !important;
    }

    @each $key, $color in $colors {

      $bgColor: nth($color, 1);
      $textColor: nth($color, 2);

      @if $darkBackground and $key == black {
        $bgColor: #FFF;
        $textColor: #666;
      }

      @if $darkBackground and $key == gray {
        $bgColor: #333;
        $textColor: #EEE;
      }

      $colorModifiers: (
              lighter-3x: scale-color($bgColor, $lightness: 80%),
              lighter-2x: scale-color($bgColor, $lightness: 50%),
              lighter: scale-color($bgColor, $lightness: 30%),
              darker: darken($bgColor, 10%),
              darker-1x: darken($bgColor, 15%),
              darker-2x: darken($bgColor, 30%),
              darker-3x: darken($bgColor, 50%),
              opacity-1x: rgba($bgColor, 90%),
              opacity-2x: rgba($bgColor, 80%),
              opacity-3x: rgba($bgColor, 70%),
              opacity-4x: rgba($bgColor, 60%),
              opacity-5x: rgba($bgColor, 50%),
              opacity-6x: rgba($bgColor, 40%),
              opacity-7x: rgba($bgColor, 30%),
              opacity-8x: rgba($bgColor, 20%),
              opacity-9x: rgba($bgColor, 10%)
      );

      &--bd {
        @each $direction in $directions {

          $directionClass: if($direction != '', '-' + $direction, '');

          &#{$directionClass} {

            &-#{'' + $key} {
              @include getBorder($bgColor, $direction);
              // border: 1px solid $bgColor !important;

              @each $ModifierKey, $ModifierColor in $colorModifiers {
                &--#{'' + $ModifierKey} {
                  @include getBorder($ModifierColor, $direction);
                }

                &--#{'' + $ModifierKey}--dotted {
                  @include getBorder($ModifierColor, $direction, dashed);
                }
              }

              /*&--lighter-3x {
                @include getBorder(scale-color($bgColor, $lightness: 80%), $direction);
                // border: 1px solid scale-color($bgColor, $lightness: 80%) !important;
              }

              &--lighter-2x {
                @include getBorder(scale-color($bgColor, $lightness: 50%), $direction);
                // border: 1px solid scale-color($bgColor, $lightness: 50%) !important;
              }

              &--lighter {
                @include getBorder(scale-color($bgColor, $lightness: 30%), $direction);
                // border: 1px solid scale-color($bgColor, $lightness: 30%) !important;
              }

              &--darker {
                @include getBorder(darken($bgColor, 10%), $direction);
                // border: 1px solid darken($bgColor, 10%) !important;
              }*/

            }
          }
        }
        /*&-#{'' + $key} {
          @include getBorder($bgColor);
          // border: 1px solid $bgColor !important;

          &--lighter-3x {
            @include getBorder(scale-color($bgColor, $lightness: 80%));
            // border: 1px solid scale-color($bgColor, $lightness: 80%) !important;
          }

          &--lighter-2x {
            @include getBorder(scale-color($bgColor, $lightness: 50%));
            // border: 1px solid scale-color($bgColor, $lightness: 50%) !important;
          }

          &--lighter {
            @include getBorder(scale-color($bgColor, $lightness: 30%));
            // border: 1px solid scale-color($bgColor, $lightness: 30%) !important;
          }

          &--darker {
            @include getBorder(darken($bgColor, 10%));
            // border: 1px solid darken($bgColor, 10%) !important;
          }

        }*/

        &-hover {
          &-#{'' + $key} {
            border: 1px solid transparent;
            cursor: pointer;

            &:hover {
              border: 1px solid $bgColor !important;
            }

            @each $ModifierKey, $ModifierColor in $colorModifiers {
              &:hover {
                &--#{'' + $ModifierKey} {
                  border: 1px solid nth($ModifierColor, 1) !important;
                }

                &--#{'' + $ModifierKey}--dotted {
                  border: 1px dotted nth($ModifierColor, 1) !important;
                }
              }
            }

            /*&:hover {
              border: 1px solid $bgColor !important;
            }

            &--lighter {
              &:hover {
                border: 1px solid lighten($bgColor, 20%) !important;
              }
            }

            &--darker {
              &:hover {
                border: 1px solid darken($bgColor, 10%) !important;
              }
            }

            &--darker-1x {
              &:hover {
                border: 1px solid darken($bgColor, 15%) !important;
              }
            }

            &--darker-2x {
              &:hover {
                border: 1px solid darken($bgColor, 30%) !important;
              }
            }*/
          }
        }
      }

      &--bg {
        &-#{'' + $key} {
          background-color: $bgColor !important;

          &.mdc-button:not([class*="mip-color--text-"]) {
            color: $textColor !important;
          }

          .mat-simple-snackbar {
            .mdc-button {
              color: $textColor !important;
              font-weight: bold;
            }
          }

          .mdc-snackbar__surface {
            background-color: $bgColor !important;

            &, a, button {
              color: $textColor !important;
            }
          }

          &:not([class*="mip-color--text"]), a:not([class*="mip-color--text"]):not([class*="mip-color--bg"]) {
            color: $textColor !important;
          }

          @each $ModifierKey, $ModifierColor in $colorModifiers {
            &--#{'' + $ModifierKey} {
              background-color: $ModifierColor !important;

              .mat-simple-snackbar {
                .mdc-button {
                  color: $textColor !important;
                  font-weight: bold;
                }
              }

              .mdc-snackbar__surface {
                background-color: $ModifierColor !important;

                &, a, button {
                  color: $textColor !important;
                }
              }

              &:not([class*="mip-color--text"]), a:not([class*="mip-color--text"]):not([class*="mip-color--bg"]) {
                color: $textColor !important;
              }
            }
          }

          /*&,
          &--lighter-3x,
          &--lighter-2x,
          &--lighter,
          &--darker,
          &--darker-2x {
            &:not([class*="mip-color--text"]), a:not([class*="mip-color--text"]):not([class*="mip-color--bg"]) {
              color: $textColor !important;
            }
          }

          &--lighter-3x {
            background-color: scale-color($bgColor, $lightness: 80%) !important;
          }

          &--lighter-2x {
            background-color: scale-color($bgColor, $lightness: 50%) !important;
          }

          &--lighter {
            background-color: scale-color($bgColor, $lightness: 30%) !important;
          }

          &--darker {
            background-color: darken($bgColor, 10%) !important;
          }

          &--darker-1x {
            background-color: darken($bgColor, 15%) !important;
          }

          &--darker-2x {
            background-color: darken($bgColor, 30%) !important;
          }*/
        }

        &-hover {
          &-#{'' + $key} {
            cursor: pointer;

            &.mdc-button {
              &:hover {
                background-color: $bgColor !important;
                color: $textColor !important;
              }
            }

            &:hover {
              background-color: $bgColor !important;
              color: $textColor !important;
            }

            @each $ModifierKey, $ModifierColor in $colorModifiers {
              &--#{'' + $ModifierKey} {
                &:hover {
                  background-color: $ModifierColor !important;
                }
              }
            }

            /*&--lighter {
              &:hover {
                background-color: lighten($bgColor, 20%) !important;
              }
            }

            &--darker {
              &:hover {
                background-color: darken($bgColor, 10%) !important;
              }
            }

            &--darker-2x {
              &:hover {
                background-color: darken($bgColor, 30%) !important;
              }
            }*/
          }
        }
      }

      &--text {
        &-#{'' + $key} {
          color: $bgColor !important;
          --mdc-snackbar-supporting-text-color: #{$bgColor};

          &.mdc-button {
            color: $bgColor !important;
          }

          img.__icon {
            color: $bgColor !important;
            @include recolor($bgColor);
          }

          .mat-simple-snackbar {
            .mdc-button {
              color: $bgColor !important;
              font-weight: bold;
            }
          }

          @each $ModifierKey, $ModifierColor in $colorModifiers {
            &--#{'' + $ModifierKey} {
              color: $ModifierColor !important;

              .mat-simple-snackbar {
                .mdc-button {
                  color: $ModifierColor !important;
                  font-weight: bold;
                }
              }
            }
          }

          /*&--lighter {
            color: lighten($bgColor, 20%) !important;
          }

          &--darker {
            color: darken($bgColor, 10%) !important;
          }

          &--darker-2x {
            color: darken($bgColor, 30%) !important;
          }*/
        }

        &-hover {
          &-#{'' + $key} {
            cursor: pointer;

            &:hover {
              color: $bgColor !important;
            }

            @each $ModifierKey, $ModifierColor in $colorModifiers {
              &--#{'' + $ModifierKey} {
                &:hover {
                  color: $ModifierColor !important;
                }
              }
            }
            /*
            &--lighter {
              &:hover {
                color: lighten($bgColor, 20%) !important;
              }
            }

            &--darker {
              &:hover {
                color: darken($bgColor, 10%) !important;
              }
            }

            &--darker-2x {
              &:hover {
                color: darken($bgColor, 30%) !important;
              }
            }*/
          }
        }
      }

    }
  }
}
