@import "libs/gc-common/src/assets/scss/utils/flex";
@import "libs/gc-common/src/assets/scss/utils/padding";
@import "libs/gc-common/src/assets/scss/utils/margin";
// @import "libs/gc-common/src/assets/scss/mat-colors";
@import "libs/gc-common/src/assets/scss/mip-colors";

.--no-shadow {
  box-shadow: none !important;
}

.--float {
  &-left,
  &-right {
    position: absolute !important;
    height: 100%;
    z-index: 10;
  }

  &-left {
    left: 0;
  }

  &-right {
    right: 0;
  }
}

.--text {
  &-bold {
    font-weight: bold !important;
  }

  &-align {
    &--left {
      text-align: left !important;

      & > .mdc-button-wrapper {
        justify-content: flex-start !important;
      }
    }

    &--center {
      text-align: center !important;

      & > .mdc-button-wrapper {
        justify-content: center !important;
      }
    }

    &--right {
      text-align: right !important;

      & > .mdc-button-wrapper {
        justify-content: flex-end !important;
      }
    }
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-lowercase {
    text-transform: lowercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }
}

.--nowrap {
  white-space: nowrap;
}

.--is-disabled {
  opacity: 0.6;
  position: relative;
  pointer-events: all;

  &:before {
    content: "";
    display: block;
    pointer-events: all;
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: calc(100% + 10px);
    z-index: 10;
  }
}

// mip-color--bg-top-danger
@include renderMipColor();

$iconScale: 1.17;
$iconNextSize: 14px;

.gc-icon {
  &--size {
    @for $i from 1 through 25 {

      &-#{$i}x {
        width: $iconNextSize;
        height: $iconNextSize;
        min-width: $iconNextSize;
        min-height: $iconNextSize;
        max-width: $iconNextSize;
        max-height: $iconNextSize;
        font-size: $iconNextSize;
        line-height: $iconNextSize;

        &.--force {
          width: $iconNextSize !important;
          height: $iconNextSize !important;
          min-width: $iconNextSize !important;
          min-height: $iconNextSize !important;
          max-width: $iconNextSize !important;
          max-height: $iconNextSize !important;
          font-size: $iconNextSize !important;
          line-height: $iconNextSize !important;
        }
      }

      $iconNextSize: floor($iconNextSize * $iconScale);

    }
  }
}
