
@import '@angular/material';
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "node_modules/tippy.js/themes/light.css";
@import "node_modules/swiper/swiper";
@import "node_modules/swiper/modules/navigation/navigation";
@import "node_modules/swiper/modules/pagination/pagination";
@import 'node_modules/tippy.js/dist/tippy.css';
// @import 'node_modules/tippy.js/themes/light.css';
// @import 'node_modules/tippy.js/themes/light-border.css';
// @import 'node_modules/tippy.js/themes/material.css';
// @import 'node_modules/tippy.js/themes/translucent.css';

@import "assets/scss/variables";

// modifications
@import "assets/scss/mip-colors";
@import "assets/scss/mip-form";
@import "assets/scss/mip-button";
@import "assets/scss/mip-snack";
@import "assets/scss/mat-fixes";
@import "assets/scss/mat-icon";
// @import "assets/scss/mat-colors";
@import "assets/scss/horizontal";
@import "assets/scss/utils-classes";
@import "assets/mip-icons/style";
@import "assets/scss/tippy-theme";

// replacing Material Design typography
html,
body,
.mat-body,
.mat-body-1,
.mat-typography,
.mdc-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: 'Noto Sans', sans-serif !important;
}

html,
body {
  // position: relative !important;
  top: 0 !important;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  // display: flex;
  // overflow: hidden;
  // width: 100%;
  // min-height: 100%;
  background: #fafafa !important;
  // color: #333;

  // background-color: getMipColor('light');

  margin: 0 !important;
  // font-family: 'Open Sans', sans-serif;
  // font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;

  .grecaptcha-badge {
    display: none;
  }

  &.--is-mobile {
    // overflow: hidden;
  }

  &.--is-desktop {
    // min-width: $mainContainerMinWidth;
  }

  &[smartbanner-visible] {
    .network.--is-mobile {
      &, .mip-modal {
        top: 84px;
        height: calc(100% - 84px);
      }
    }
  }
}

.altcha-widget-container {
  position: fixed;
  top: -300px;
  z-index: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

a {
  text-decoration: none;
}

*, :after, :before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
  letter-spacing: 0.5px !important;
}

p {
  margin: 0 !important;

  & + p {
    margin-top: 12px !important;
  }
}

ul.within-viewport-layers {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;

  &, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: absolute;
    background: rgba(red, 0.3);
    width: 100px;

    span {
      position: absolute;
      width: 100%;
      height: 2px;

      &:nth-child(1) {
        background: blue;
      }

      &:nth-child(2) {
        background: green;
      }
    }

    &:nth-child(1) {
      margin-left: 20px;
    }

    &:nth-child(2) {
      margin-left: 40px;
    }

    &:nth-child(3) {
      margin-left: 60px;
    }

    &:nth-child(4) {
      margin-left: 80px;
    }
  }
}

.mip-lazy-img-loading {
  background-image: url('https://cdn.gotchosen.com/mip/ssr/assets/images/loading.gif') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 30% !important;
}

.mat-typography {
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
}

.video-player-element {
  // object-fit: cover !important;
}

.cdk-global-scrollblock {
  position: initial !important;
  overflow-y: hidden !important;
}

.--modal-opened {
  max-height: 100vh;
  overflow: hidden;

  .__main-menu-bar {
    display: none !important;
  }

  .__pages-content {
    padding-bottom: 0 !important;
  }

  mip-top-menu-bar {
    z-index: 0 !important;
  }

  mat-drawer-container {
    overflow: visible !important;
    max-height: 100% !important;
  }

  mat-drawer {
    z-index: 0 !important;
  }
}

textarea {
  line-height: 23px !important;
}

hr {
  background: rgba(#999, 0.2);
  border: none;
  height: 1px;
  width: 100%;

  &.--with-margin {
    margin: 20px 0;
  }
}

.gcwp-advertisement-label {
  font-family: inherit !important;
}

ul, ol {
  // font-size: 16px;
}

[disabled] {
  opacity: 0.4 !important;
}

.video-placeholder {
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
    position: relative;
    z-index: 0;
  }

  iframe, video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Hide scrollbar by default */
::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
  background-color: transparent;
}

/* Make scrollbar visible when needed */
::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Make scrollbar track visible when needed */
::-webkit-scrollbar-track {
  background-color: transparent;
}

*:hover {
  ::-webkit-scrollbar {
    background-color: rgba(#000, 0.05);
  }

  /* Make scrollbar visible when needed */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(#000, 0.10);
  }
}

*:not(:hover) {
  ::-webkit-scrollbar {
    background-color: transparent;
  }
}
