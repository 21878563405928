@import "libs/gc-common/src/assets/scss/mip-colors";

.mdc-button,
.mdc-raised-button,
.mdc-stroked-button,
.mdc-flat-button,
.mdc-icon-button,
.mdc-fab,
.mdc-mini-fab,
button.mat-mdc-menu-item,
a.mat-mdc-menu-item {
  min-width: auto !important;
  justify-content: center !important;
  align-items: center;
  // align-content: center;
  display: flex !important;

  &.mat-mdc-button.mat-mdc-button-base {
    height: auto;
  }

  .mat-mdc-button-touch-target {
    height: 100% !important;
  }

  &__label {
    &, span {
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &[disabled] {
    opacity: 0.7;
  }

  &.--hide-button {
    display: none !important;
  }

  &.--align-left {
    .mdc-button__label {
      justify-content: flex-start !important;

      span {
        justify-content: flex-start !important;
      }
    }
  }

  &.--align-center {
    .mdc-button__label {
      justify-content: center !important;

      span {
        width: auto !important;
      }
    }
  }

  &.--nowrap {
    .mdc-button__label {
      white-space: nowrap;
    }
  }

  &.--button-row {
    .mdc-button__label {
      flex-direction: column;
    }
  }

  &.--full {
    width: 100%;

    .mdc-button__label {
      span {
        width: 100%;
        text-align: left;
      }
    }
  }

  &.--icon-aside {
    .mdc-list-item__primary-text,
    .mdc-button__label {
      & > span {
        width: 100%;

        & + mip-icon,
        & + mip-loading,
        & + mip-avatar {
          margin-left: 10px;
        }
      }
    }

    mip-icon,
    mip-loading,
    mip-avatar {
      & + span {
        margin-left: 10px;
        width: 100%;
      }
    }
  }

  &.--ellipsis {
    .mdc-button__label {
      display: block !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .mdc-button__label {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    // flex-direction: column;
    width: 100% !important;
    padding: 0 !important;
    line-height: normal !important;
    min-height: 100%;
    white-space: normal;

    &:empty {
      display: none !important;
    }
  }

  &:not(.--button-row) {
    mat-icon, mip-icon, mip-avatar {
      & + span,
      & + strong {
        margin-left: 6px;
      }
    }
  }

}

.mdc-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 15px !important;
  // box-shadow: none !important;
  min-height: 36px;

  mat-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  mat-icon + .mdc-button__label,
  .mdc-button__label + mat-icon,
  mip-icon + span,
  mip-badge + span,
  span + mip-badge,
  span + mip-icon,
  mip-loading + span,
  span + mip-loading,
  mip-avatar + span,
  span + mip-avatar {
    margin-left: 10px !important;
  }

  .mdc-button__label {
    margin: 0;
    min-height: 100%;
    white-space: normal;
  }

  &.--button-row {
    .mdc-button__label {
      padding: 10px 0 !important;
    }
  }

  &.--small-button {
    padding: 0 10px !important;
    min-height: 26px;

    .mdc-button__label {
      font-size: 12px;
    }
  }

}

.mdc-icon-button.mat-mdc-button-base {
  padding: 0;
}

.mat-card-header-text {
  margin: 0 !important;
  min-height: 36px;

  .mat-card-title {
    margin: 0 !important;
  }
}

// .mat-fab
body:not(.--is-desktop) {
  .cdk-overlay-pane {
    // width: 100%;
  }
}

.cdk-overlay-pane {
  justify-content: center;
  // width: 100%;

  &-custom {

    &--no-overflow {
      .mat-mdc-optgroup-label,
      .mat-mdc-select-panel,
      .mdc-list-item__primary-text {
        overflow: visible !important;
      }

      .mat-mdc-select-panel {
        max-height: none !important;
      }
    }

    &--full {
      justify-content: flex-start;

      &--right {
        justify-content: flex-end;
      }

      .mat-mdc-optgroup-label,
      .mat-mdc-select-panel,
      .mdc-list-item__primary-text {
        overflow: visible !important;
      }

      .mat-mdc-optgroup-label {
        font-size: 14px;
        opacity: 0.6;
      }

      .mat-mdc-select-panel {
        max-height: none !important;
        width: auto !important;
      }

      .mdc-list-item__primary-text {
        white-space: nowrap !important;
      }
    }
  }

  .mat-snack-bar-container {
    width: auto !important;
  }
}

.mat-mdc-form-field {

  &.--has-hint {
    .mat-mdc-form-field-wrapper {
      // margin-bottom: 30px;
    }
  }

  .mat-input-element {
    font-size: 16px;
  }

  .mat-mdc-form-field-icon-prefix {
    padding: 10px;
  }

  .mat-mdc-form-field-icon-suffix {
    display: flex;
    padding: 10px;

    .mdc-button {

      &:not([class*="mip-color--bg"]) {
        padding: 0 5px !important;
      }

      & + .mdc-button {
        margin-left: 10px;
      }
    }
  }
}

mat-list, mat-nav-list {

  .mat-mdc-list-item {
    height: auto !important;
    border-radius: 5px;
  }

  &.--stretch-content {
    .mat-list-item-content {
      align-items: stretch !important;
    }
  }

}

.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
}

.mat-tab-body-wrapper, .mat-tab-body, .mat-tab-body-content {
  // overflow-x: hidden !important;
  overflow-y: unset !important;
  // overflow: unset !important;
}

.mat-menu {
  &--arrowed {
    margin-top: 10px !important;
    overflow: visible !important;
    box-shadow: 0 0 25px -10px !important;
    border-radius: 10px !important;
    padding: 10px !important;

    &:before {
      display: block;
      content: "";
      position: absolute;
      top: -7px;
      right: 15px;
      z-index: 5;
      width: 0;
      height: 0;
      transform: rotate(-45deg);
      border-top: 8px solid #FFF;
      border-right: 8px solid #FFF;
      border-bottom: 8px solid transparent;
      border-left: 8px solid transparent;
    }
  }
}

.mat-mdc-menu-panel {
  min-height: 48px !important;
  min-width: 150px !important;
  max-width: 320px !important;

  mip-icon {
    mat-icon {
      margin: 0 !important;
    }
  }

  &.--width-full {
    max-width: none !important;
    width: 100%;
  }

  .mat-mdc-menu-content {
    padding: 0 !important;

    .mat-mdc-menu-item,
    .mdc-list-item {
      height: auto;
      min-height: 48px;
      line-height: inherit;
      padding: 5px 15px;
      display: flex;
      align-items: center;

      &, &__primary-text {
        display: flex;
        align-items: center;

        &, mat-checkbox {
          width: 100%;
        }
      }

      &.--divider {
        border-top: 2px solid #EEE;
      }

      & > span {

        & > mip-icon + span,
        & > mip-badge + span,
        & > span + mip-badge,
        & > span + mip-icon,
        & > mip-loading + span,
        & > span + mip-loading,
        & > mip-avatar + span,
        & > span + mip-avatar {
          margin-left: 10px;
        }
      }
    }

    .--active {
      background: #EEE;
      color: getMipColor('primary');
    }
  }
}

.mat-mdc-select {
  &--width-auto {
    .mat-mdc-form-field-infix {
      width: auto !important;
      min-width: 180px;
    }
  }
}

.mat-mdc-select-panel {
  border-radius: 5px !important;
  padding: 0 !important;

  .mat-mdc-optgroup-label {
    min-height: 35px;
  }

  .mdc-list-item {
    min-height: 40px;
  }

  mat-optgroup {
    display: block;

    & + mat-optgroup {
      border-top: 1px solid #EEE;
    }
  }
}

.cdk-overlay-pane {
  // width: 100% !important;
}

.mat-bottom-sheet-container {
  border-radius: 10px 10px 0 0;
  padding: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  max-width: 500px !important;
  min-width: auto !important;
  width: 100% !important;
  transition: border-radius 0.2s ease-in-out;
  overflow: hidden !important;

  &.--keyboard-is-open {
    border-radius: 0 !important;
  }

  .mat-list-item + .mat-list-item {
    border-top: 1px solid #EEE;
  }

  .mat-list-text {
    text-align: center;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.mat-mdc-tab {

  &-header {
    margin-bottom: 8px;
    border-bottom: none;
  }

  &-label-container {
    // overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }

    .mat-mdc-tab-labels {

      .mdc-tab {
        opacity: 0.6;
        transition: opacity 0.3s ease-in-out;

        &__text-label {
          color: #333 !important;
          font-size: 16px;
          font-weight: normal;
          white-space: break-spaces;
        }

        &-indicator {

          &__content {
            border-color: getMipColor('primary') !important;
          }

          &--active {
            opacity: 1;
          }
        }
      }

    }
  }
}

.mat-snack-bar-container {
  span {
    word-break: break-all;
  }
}

.mat-mdc-tab-group {

  .mat-mdc-tab-header {
    margin-bottom: 0;
  }

  .mat-mdc-tab-body {
    display: flex;
    flex-direction: column;
  }

  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .mat-mdc-tab-body-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto !important;
  }

  .mdc-list-item__content {

  }
}

mat-checkbox {

  .mdc-form-field {
    align-items: stretch;

    &, label {
      width: 100%;
    }

    label {
      display: flex;
      align-items: center;
    }
  }

  input[type="checkbox"] {
    &[disabled] {
      opacity: 0 !important;
    }
  }
}

.mat-mdc-form-field {

}

.mat-mdc-form-field-hint {
  width: 100%;
  display: flex;
  justify-content: space-between;

  & + .mat-mdc-form-field-hint-spacer {
    display: none;
  }
}

.mat-form-field {

  &--textarea {
    .mat-mdc-form-field-icon-prefix {
      align-self: flex-start;
      margin-top: 10px;
    }
  }

  mat-datepicker-toggle {
    .mdc-icon-button {
      padding: 0 !important;
    }
  }

  &--border {
    &-rounded {

      .mat-mdc-text-field-wrapper {
        // padding: 0 25px !important;
      }

      .mdc-floating-label {
        background: #FFF;
        max-width: none;
        padding: 0 5px;
      }

      .mat-mdc-form-field-icon-prefix {
        padding-left: 15px;
      }

      .mat-mdc-form-field-icon-suffix {
      }

      .mdc-notched-outline {
        border: 1px solid #000;
        border-radius: 50px;

        &__leading, &__notch, &__trailing {
          border: none !important;
          border-radius: 0 !important;
        }
      }
    }

    &-radios {
      &-md {
        .mdc-notched-outline {
          &__leading {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
          }

          &__trailing {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
          }
        }
      }
    }
  }

  .mat-mdc-select-arrow-wrapper {
    transform: none !important;
  }

  &--size {
    &-sm {
      .mat-mdc-form-field-flex,
      .mdc-notched-outline {
        height: 40px;
      }

      .mdc-floating-label {
        top: 19px !important;
        font-size: 14px !important;

        &--float-above {
          transform: translateY(-29px) scale(0.75) !important;
        }
      }

      .mat-mdc-form-field-icon-prefix /*,
      .mat-mdc-form-field-icon-suffix*/
      {
        padding: 0 10px !important;

        button {
          height: 30px;
          width: 30px;
        }
      }

      .mat-date-range-input-container {
        top: 2px;
        position: relative;
      }

      .mat-mdc-form-field-infix {
        min-height: auto !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        width: 70px !important;
      }

      .mdc-notched-outline__notch {
        max-width: calc(100% - 35px) !important;
      }
    }

    &-md {
      .mdc-floating-label {
        top: 22px !important;

        &--float-above {
          transform: translateY(-29px) scale(0.75) !important;
        }
      }

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-icon-suffix {
        padding: 0 10px !important;

        &:not(.mat-mdc-form-field-icon-suffix) {
          button {
            width: 48px;
            height: 48px;
          }
        }
      }

      .mat-date-range-input-container {
        top: 2px;
        position: relative;
      }

      .mat-mdc-form-field-infix {
        min-height: auto !important;
        padding-top: 12px !important; // 8px !important;
        padding-bottom: 12px !important; // 8px !important;
        // width: auto !important;
      }
    }

    &-lg {
      .mdc-floating-label {
        top: 24px !important;

        &--float-above {
          transform: translateY(-33px) scale(0.75) !important;
        }
      }

      .mat-mdc-form-field-icon-prefix /*,
      .mat-mdc-form-field-icon-suffix*/
      {
        button {
          height: 50px;
          width: 50px;
        }
      }

      .mat-date-range-input-container {
        top: 2px;
        position: relative;
      }

      .mat-mdc-form-field-infix {
        min-height: auto !important;
        padding-top: 13px !important;
        padding-bottom: 13px !important;
        // width: auto !important;
      }
    }
  }

  &--no-border {
    .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
        border-color: transparent !important;
      }
    }
  }

  &--theme {
    &-light {
      .mat-mdc-select-arrow,
      mat-label {
        color: #FFF !important;
      }

      .mat-mdc-text-field-wrapper:hover {
        .mdc-notched-outline {

          .mat-mdc-select-arrow,
          mat-label {
            color: #FFF !important;
          }

          &__leading, &__notch, &__trailing {
            border-color: #FFF !important;
          }
        }
      }

      .mat-mdc-select-value {
        color: #FFF !important;
      }

      .mdc-notched-outline {
        &__leading, &__notch, &__trailing {
          border-color: #FFF !important;
        }
      }
    }
  }
}

.mdc-tooltip {
  background: #000 !important;
}

.mat-expansion-panel {
  .mat-expansion-indicator {
    padding: 5px;
  }
}

.mdc-snackbar {
  &, &__surface {
    border-radius: 4px;
  }
}

.mat-stepper {
  &--hide-steps {
    background: none !important;

    .mat-horizontal,
    .mat-vertical {
      margin: 0;

      &:before {
        display: none;
      }

      &-stepper-header {
        &, &-container {
          display: none;
        }
      }

      &-content {
        &, &-container {
          padding: 0;
        }
      }
    }
  }
}


@media screen and (max-width: 500px) {
  .cdk-overlay-pane {
    // min-height: 100%;
    width: 100%;
  }
  .mat-bottom-sheet-container {
    max-height: 100vh !important;
  }
}
