$icomoon-font-family: "mip-icons" !default;
$icomoon-font-path: "fonts" !default;

$mip-icon-gotchosen-thin: "\ea30";
$mip-icon-instagram-thin: "\ea2f";
$mip-icon-gc-hashtag-contest: "\e933";
$mip-icon-read-notification: "\ea21";
$mip-icon-gc-feed-comments1: "\ea1d";
$mip-icon-gc-gotchosen-coupons: "\ea1b";
$mip-icon-gc-reactions: "\ea1a";
$mip-icon-gc-gotcoin: "\ea18";
$mip-icon-gc-megaphone: "\ea14";
$mip-icon-gc-payments: "\ea15";
$mip-icon-gc-program-ads: "\ea16";
$mip-icon-gc-program-affiliate: "\ea17";
$mip-icon-gc-program-wallet: "\ea19";
$mip-icon-gc-top-10: "\ea1c";
$mip-icon-report: "\ea22";
$mip-icon-ban: "\ea23";
$mip-icon-gc-challenge-alt-2: "\ea04";
$mip-icon-gc-duet: "\ea05";
$mip-icon-gc-feed-play-full: "\ea03";
$mip-icon-monetization-on: "\ea12";
$mip-icon-gc-feed-play: "\ea13";
$mip-icon-unpin: "\ea26";
$mip-icon-gc-reactions-outline: "\e900";
$mip-icon-feedPlus: "\ea24";
$mip-icon-gc-challenge-alt-21: "\ea0e";
$mip-icon-gc-duet1: "\ea0f";
$mip-icon-gc-feed-comments: "\ea10";
$mip-icon-gc-feed-share: "\ea11";
$mip-icon-gc-chosen-path1: "\ea06";
$mip-icon-gc-chosen-path2: "\ea07";
$mip-icon-gc-verified-account-path1: "\ea08";
$mip-icon-gc-verified-account-path2: "\ea09";
$mip-icon-gc-ambassadors-colored-path1: "\ea0a";
$mip-icon-gc-ambassadors-colored-path2: "\ea0b";
$mip-icon-gc-feed-chat: "\ea00";
$mip-icon-photo-camera1: "\e9df";
$mip-icon-more: "\e9e0";
$mip-icon-share2: "\e9e5";
$mip-icon-comments1: "\e9e6";
$mip-icon-feed: "\e9e1";
$mip-icon-feed_fill: "\e9e2";
$mip-icon-notifications: "\e9e3";
$mip-icon-notifications_fill: "\e9e4";
$mip-icon-profile: "\e9fe";
$mip-icon-profile_fill: "\e9ff";
$mip-icon-wallet-gc: "\e9da";
$mip-icon-gotcoin: "\e9d9";
$mip-icon-timer: "\e905";
$mip-icon-post-add: "\e906";
$mip-icon-chosen: "\e907";
$mip-icon-chosen-plus: "\e9db";
$mip-icon-gotchosen-coupons: "\e9dc";
$mip-icon-cover: "\e9c7";
$mip-icon-new_message: "\e9ba";
$mip-icon-attention_message-path1: "\e9bb";
$mip-icon-attention_message-path2: "\e9bc";
$mip-icon-attention_message-path3: "\e9bd";
$mip-icon-block_message-path1: "\e9be";
$mip-icon-block_message-path2: "\e9bf";
$mip-icon-block_message-path3: "\e9c0";
$mip-icon-block_message-path4: "\e9c1";
$mip-icon-block_message-path5: "\e9c2";
$mip-icon-block_message-path6: "\e9c3";
$mip-icon-error_message-path1: "\e9c4";
$mip-icon-error_message-path2: "\e9c5";
$mip-icon-error_message-path3: "\e9c6";
$mip-icon-retract: "\e917";
$mip-icon-expand: "\e9b9";
$mip-icon-sequence-theme-light: "\e9d7";
$mip-icon-single-theme-light: "\ea0c";
$mip-icon-challenge-theme-light: "\ea0d";
$mip-icon-mirror-theme-light: "\ea25";
$mip-icon-photo-theme-light: "\ea27";
$mip-icon-single-theme-bold: "\ea28";
$mip-icon-sequence-theme-bold: "\ea29";
$mip-icon-mirror-theme-bold: "\ea2a";
$mip-icon-challenge-theme-bold: "\ea2b";
$mip-icon-photo-theme-bold: "\ea2c";
$mip-icon-dash_menuclosed: "\e999";
$mip-icon-dash_home: "\e99a";
$mip-icon-dash_bonus: "\e99b";
$mip-icon-dash_settings: "\e99c";
$mip-icon-dash_support: "\e99d";
$mip-icon-dash_performance: "\e99e";
$mip-icon-dash_payments: "\e99f";
$mip-icon-dash_off: "\e9a1";
$mip-icon-dash_menuopen: "\e9a2";
$mip-icon-dash_offmenu: "\e9a3";
$mip-icon-text-tool-select: "\e908";
$mip-icon-text-tool: "\e909";
$mip-icon-background-tool: "\e973";
$mip-icon-background-tool-select: "\e9ce";
$mip-icon-timer_invite: "\e9cf";
$mip-icon-search: "\e9dd";
$mip-icon-chat: "\e9a0";
$mip-icon-dash-faq: "\e9a5";
$mip-icon-dash_faq: "\e9a5";
$mip-icon-at-symbol: "\e9b6";
$mip-icon-logout: "\e9ad";
$mip-icon-paperclip: "\e9a6";
$mip-icon-share: "\e9a7";
$mip-icon-warning: "\e992";
$mip-icon-video-camera: "\e98b";
$mip-icon-photo-camera: "\e98c";
$mip-icon-lock: "\e985";
$mip-icon-text: "\e984";
$mip-icon-gif: "\e986";
$mip-icon-sticker: "\e911";
$mip-icon-dollar-circle: "\e920";
$mip-icon-chevron-left-small: "\e94a";
$mip-icon-chevron-right-small: "\e94b";
$mip-icon-chevron-up-small: "\e94c";
$mip-icon-chevron-down-small: "\e94d";
$mip-icon-flip: "\e971";
$mip-icon-cut: "\e972";
$mip-icon-effects: "\e975";
$mip-icon-gear: "\e955";
$mip-icon-equalizer: "\e95e";
$mip-icon-code: "\e95f";
$mip-icon-network: "\e960";
$mip-icon-heart2: "\e961";
$mip-icon-star: "\e962";
$mip-icon-bell: "\e963";
$mip-icon-bell2: "\e964";
$mip-icon-paperclip1: "\e965";
$mip-icon-notification: "\e912";
$mip-icon-direct: "\e91f";
$mip-icon-claquete: "\e93a";
$mip-icon-shining-star: "\e93b";
$mip-icon-heart: "\e93c";
$mip-icon-trophy: "\e93d";
$mip-icon-reactions: "\e93e";
$mip-icon-calendar: "\e941";
$mip-icon-money: "\e94e";
$mip-icon-refresh: "\e95b";
$mip-icon-picture: "\e956";
$mip-icon-message: "\e957";
$mip-icon-ellipsis: "\e958";
$mip-icon-times: "\e9b8";
$mip-icon-chevron-left: "\e946";
$mip-icon-chevron-right: "\e947";
$mip-icon-chevron-up: "\e948";
$mip-icon-chevron-down: "\e949";
$mip-icon-play: "\e93f";
$mip-icon-pause: "\e940";
$mip-icon-home: "\e922";
$mip-icon-new-content: "\e91e";
$mip-icon-user: "\e915";
$mip-icon-add-user: "\e914";
$mip-icon-users: "\e916";
$mip-icon-timer1: "\e918";
$mip-icon-share1: "\e97f";
$mip-icon-rollback: "\e980";
$mip-icon-download: "\e981";
$mip-icon-flip-camera: "\e982";
$mip-icon-trash: "\e983";
$mip-icon-at-symbol-bold: "\e9b7";
$mip-icon-upload-bold: "\e96a";
$mip-icon-network-bold: "\e9ee";
$mip-icon-plus-bold: "\e9ef";
$mip-icon-minus-bold: "\e9f0";
$mip-icon-refresh-bold: "\e9f2";
$mip-icon-chevron-left-bold: "\e9f3";
$mip-icon-chevron-right-bold: "\e9f4";
$mip-icon-chevron-up-bold: "\e9f5";
$mip-icon-chevron-down-bold: "\e9f6";
$mip-icon-comments-solid: "\e9e8";
$mip-icon-video-camera-solid: "\e9ac";
$mip-icon-paperclip-solid: "\e9a9";
$mip-icon-warning-solid: "\e993";
$mip-icon-step-1-solid: "\e98f";
$mip-icon-step-2-solid: "\e990";
$mip-icon-step-3-solid: "\e991";
$mip-icon-gear-solid: "\e96b";
$mip-icon-equalizer-solid: "\e96c";
$mip-icon-heart2-solid: "\e96d";
$mip-icon-star-solid: "\e96e";
$mip-icon-bell-solid: "\e96f";
$mip-icon-bell2-solid: "\e97e";
$mip-icon-trophy-solid: "\e976";
$mip-icon-times-solid1: "\e97d";
$mip-icon-play-solid: "\e9c8";
$mip-icon-pause-solid: "\e945";
$mip-icon-url: "\e903";
$mip-icon-edit: "\e988";
$mip-icon-check: "\e950";
$mip-icon-menu: "\e913";
$mip-icon-trash-bold: "\e9b3";
$mip-icon-pause-bold: "\e9f7";
$mip-icon-play-bold: "\e9f8";
$mip-icon-share-solid: "\e9e7";
$mip-icon-volume-on-solid: "\e994";
$mip-icon-volume-off-solid: "\e995";
$mip-icon-tag-solid: "\e925";
$mip-icon-notification-solid: "\e926";
$mip-icon-flash-solid: "\e927";
$mip-icon-flash-a-solid: "\e929";
$mip-icon-flash-crossed-solid: "\e92b";
$mip-icon-new-content-solid: "\e92c";
$mip-icon-user-solid: "\e92d";
$mip-icon-mobile-devices: "\ea1f";
$mip-icon-share-gc: "\e9d8";
$mip-icon-tiktok: "\e9aa";
$mip-icon-titok-rounded: "\e98e";
$mip-icon-instagram-rounded: "\e98d";
$mip-icon-facebook-rounded: "\e987";
$mip-icon-twitter-rounded: "\e989";
$mip-icon-gc-hand: "\61";
$mip-icon-gc-hand-circle: "\62";
$mip-icon-gdpr: "\79";
$mip-icon-facebook: "\e951";
$mip-icon-instagram: "\e952";
$mip-icon-twitter: "\e953";
$mip-icon-youtube: "\e954";
$mip-icon-arrow-right: "\e90c";
$mip-icon-arrow-left: "\e90d";
$mip-icon-arrow-down: "\e90e";
$mip-icon-arrow-up: "\e90f";
$mip-icon-attachment: "\e977";
$mip-icon-sound-mix: "\e978";
$mip-icon-location-bold: "\e91c";
$mip-icon-globe-bold: "\e91d";
$mip-icon-ellipsis-bold: "\e969";
$mip-icon-reactions-bold: "\e9f1";
$mip-icon-capitalize-bold: "\e9f9";
$mip-icon-download-bold: "\e9fa";
$mip-icon-rollback-bold: "\e9fb";
$mip-icon-check-bold: "\e9fc";
$mip-icon-dollar-bold: "\e9fd";
$mip-icon-send-solid: "\e924";
$mip-icon-triangle-down-solid: "\e979";
$mip-icon-triangle-left-solid: "\e97a";
$mip-icon-triangle-right-solid: "\e97b";
$mip-icon-triangle-up-solid: "\e97c";
$mip-icon-volume-mute: "\ea01";
$mip-icon-volume: "\ea01";
$mip-icon-audio: "\ea01";
$mip-icon-player: "\ea01";
$mip-icon-volume-medium: "\ea02";
$mip-icon-volume1: "\ea02";
$mip-icon-audio1: "\ea02";
$mip-icon-speaker: "\ea02";
$mip-icon-player1: "\ea02";
$mip-icon-tiktok-button: "\e92e";
$mip-icon-twitter-button: "\e92f";
$mip-icon-facebook-button: "\e930";
$mip-icon-youtube-button: "\e931";
$mip-icon-instagram-button: "\e932";
$mip-icon-apple: "\ea1e";
$mip-icon-google: "\e904";
$mip-icon-whatsapp: "\e90a";
$mip-icon-hashtag: "\e928";
$mip-icon-carnival: "\e90b";
$mip-icon-tutorials: "\e974";
$mip-icon-comments: "\e910";
$mip-icon-mail: "\e942";
$mip-icon-loading: "\e998";
$mip-icon-plus: "\e970";
$mip-icon-following-bold: "\e901";
$mip-icon-hashtag-bold: "\e94f";
$mip-icon-menu-bold: "\e921";
$mip-icon-search-bold: "\e9de";
$mip-icon-tutorials-bold: "\e959";
$mip-icon-home-bold: "\e9eb";
$mip-icon-profile-bold: "\e9ec";
$mip-icon-dash-faq-bold: "\e95a";
$mip-icon-gc-hand-circle-bold: "\e95c";
$mip-icon-carnival-bold: "\e91b";
$mip-icon-ondemand-bold: "\e95d";
$mip-icon-post-bold: "\e966";
$mip-icon-timer-bold: "\e967";
$mip-icon-gotcoin-bold: "\e9ed";
$mip-icon-arrow-right-bold: "\e968";
$mip-icon-arrow-left-bold: "\e9a4";
$mip-icon-arrow-down-bold: "\e9af";
$mip-icon-arrow-up-bold: "\e9b0";
$mip-icon-block-bold: "\e9b1";
$mip-icon-link-bold: "\e9cb";
$mip-icon-carnival-solid: "\e92a";
$mip-icon-gc-coupon-solid: "\e9a8";
$mip-icon-article-solid: "\e9b2";
$mip-icon-gc-hand-circle-solid: "\e9ae";
$mip-icon-photo-camera-solid: "\e9ab";
$mip-icon-phone-solid: "\e9c9";
$mip-icon-volume-on2-solid: "\e996";
$mip-icon-volume-off2-solid: "\e997";
$mip-icon-eye-solid: "\e98a";
$mip-icon-smiley-face: "\e934";
$mip-icon-happy-face: "\e935";
$mip-icon-loved-face: "\e936";
$mip-icon-laughing-face: "\e937";
$mip-icon-surprised-face: "\e938";
$mip-icon-sleeping-face: "\e939";
$mip-icon-crying-face: "\e9ca";
$mip-icon-angry-face: "\e9cc";
$mip-icon-smiley-face-circle: "\e9cd";
$mip-icon-happy-face-circle: "\e9d0";
$mip-icon-loved-face-circle: "\e9d1";
$mip-icon-laughing-face-circle: "\e9d2";
$mip-icon-surprised-face-circle: "\e9d3";
$mip-icon-sleeping-face-circle: "\e9d4";
$mip-icon-crying-face-circle: "\e9d5";
$mip-icon-angry-face-circle: "\e9d6";
$mip-icon-envelope-o: "\ea20";
$mip-icon-phone-play-bold: "\ea2d";
$mip-icon-monitor-ad-bold: "\ea2e";
$mip-icon-following-solid: "\e902";
$mip-icon-tutorials-solid: "\e943";
$mip-icon-dash-faq-solid: "\e944";
$mip-icon-home-solid: "\e9e9";
$mip-icon-profile-solid: "\e9ea";
$mip-icon-check-solid: "\e919";
$mip-icon-clock-solid: "\e91a";
$mip-icon-hashtag-solid: "\e923";
$mip-icon-times-solid: "\e9b4";
$mip-icon-trash-solid: "\e9b5";

