.--no-padding {
  &, .mat-list-item-content {
    padding: 0 !important;
  }
}

.--no-horizontal-padding {
  &, .mat-list-item-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.--no-vertical-padding {
  &, .mat-list-item-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
