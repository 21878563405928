@import "libs/gc-common/src/assets/scss/mip-colors";

.mip-form {

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    display: flex;
  }

  &--theme {
    &-light {
      color: getMipColor('light');

      .mdc-text-field {
        color: getMipColor('light');
        background: none !important;
      }

      .mdc-floating-label {
        color: getMipColor('light') !important;
      }

      .mat-checkbox-frame {
        border-color: getMipColor('light');
      }

      .mat-form-field-underline {
        background-color: getMipColor('light');
      }

    }
  }
}
