.--flex-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.--flex-left {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.--flex-right {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.--flex-column {
  flex-direction: column !important;

  &,
  &--start,
  &--center,
  &--end {
    display: flex !important;
  }

  &--start {
    align-items: flex-start !important;
  }

  &--center {
    align-items: center !important;
  }

  &--end {
    align-items: flex-end !important;
  }
}

.--flex-row {
  flex-direction: row !important;

  &,
  &--start,
  &--center,
  &--end,
  &--space-between,
  &--space-around {
    display: flex !important;
  }

  &--start {
    justify-content: flex-start !important;
  }

  &--center {
    justify-content: center !important;
  }

  &--end {
    justify-content: flex-end !important;
  }

  &--space-between {
    justify-content: space-between !important;
  }

  &--space-around {
    justify-content: space-around !important;
  }
}
