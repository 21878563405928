.mip-button {
  &--size {

    &-xxs {
      min-height: 17px !important;
      height: auto !important;

      &.mdc-icon-button {
        width: 17px !important;
        height: 17px !important;
      }

      &.--adjust-text {
        font-size: 10px;
      }
    }

    &-xs {
      min-height: 20px !important;
      height: auto !important;

      &.mdc-icon-button {
        width: 20px !important;
        height: 20px !important;
      }

      &.--adjust-text {
        font-size: 12px;
      }
    }

    &-sm {
      min-height: 30px !important;
      height: auto !important;

      &.mdc-icon-button {
        width: 30px !important;
        height: 30px !important;
      }

      &.--adjust-text {
        font-size: 14px;
      }
    }

    &-md {
      min-height: 40px !important;
      height: auto !important;

      &.mdc-icon-button {
        width: 40px !important;
        height: 40px !important;
      }

      &.--adjust-text {
        font-size: 18px;
      }
    }

    &-lg {
      min-height: 50px !important;
      height: auto !important;

      &.mdc-icon-button {
        width: 50px !important;
        height: 50px !important;
      }

      &.--adjust-text {
        font-size: 20px;
      }
    }

    &-xl {
      min-height: 60px !important;
      height: auto !important;

      &.mdc-icon-button {
        width: 60px !important;
        height: 60px !important;
      }

      &.--adjust-text {
        font-size: 24px;
      }
    }

    &-xxl {
      min-height: 70px !important;
      height: auto !important;

      &.mdc-icon-button {
        width: 70px !important;
        height: 70px !important;
      }

      &.--adjust-text {
        font-size: 34px;
      }
    }
  }

  &__group {
    display: flex;

    button, .mdc-button {
      &:not(:first-child) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    button, .mdc-button {
      &:not(:last-child) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    button + button,
    .mdc-button + .mdc-button {
      border-left: 1px solid rgba(#000, 0.1);
    }

    button, .mdc-button {
      &:not(:last-child) {
        &:first-child {
          border-right: 0 !important;
        }
      }
    }

    &--no-inner-borders {
      /*button, .mdc-button {
        &:first-child {
          border-right: 0 !important;
        }
      }*/

      button + button,
      .mdc-button + .mdc-button {
        border-left: 0 !important;
      }
    }
  }

  &--rounded {
    border-radius: 100px !important;
  }
}
