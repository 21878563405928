@import "./variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3c2g9j');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3c2g9j#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3c2g9j') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3c2g9j') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3c2g9j##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="mip-icon-"], [class*=" mip-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mip-icon-gotchosen-thin {
  &:before {
    content: $mip-icon-gotchosen-thin;
  }
}

.mip-icon-instagram-thin {
  &:before {
    content: $mip-icon-instagram-thin;
  }
}

.mip-icon-gc-hashtag-contest {
  &:before {
    content: $mip-icon-gc-hashtag-contest;
  }
}

.mip-icon-read-notification {
  &:before {
    content: $mip-icon-read-notification;
  }
}

.mip-icon-gc-feed-comments1 {
  &:before {
    content: $mip-icon-gc-feed-comments1;
  }
}

.mip-icon-gc-gotchosen-coupons {
  &:before {
    content: $mip-icon-gc-gotchosen-coupons;
  }
}

.mip-icon-gc-reactions {
  &:before {
    content: $mip-icon-gc-reactions;
  }
}

.mip-icon-gc-gotcoin {
  &:before {
    content: $mip-icon-gc-gotcoin;
  }
}

.mip-icon-gc-megaphone {
  &:before {
    content: $mip-icon-gc-megaphone;
  }
}

.mip-icon-gc-payments {
  &:before {
    content: $mip-icon-gc-payments;
  }
}

.mip-icon-gc-program-ads {
  &:before {
    content: $mip-icon-gc-program-ads;
  }
}

.mip-icon-gc-program-affiliate {
  &:before {
    content: $mip-icon-gc-program-affiliate;
  }
}

.mip-icon-gc-program-wallet {
  &:before {
    content: $mip-icon-gc-program-wallet;
  }
}

.mip-icon-gc-top-10 {
  &:before {
    content: $mip-icon-gc-top-10;
  }
}

.mip-icon-report {
  &:before {
    content: $mip-icon-report;
  }
}

.mip-icon-ban {
  &:before {
    content: $mip-icon-ban;
  }
}

.mip-icon-gc-challenge-alt-2 {
  &:before {
    content: $mip-icon-gc-challenge-alt-2;
  }
}

.mip-icon-gc-duet {
  &:before {
    content: $mip-icon-gc-duet;
  }
}

.mip-icon-gc-feed-play-full {
  &:before {
    content: $mip-icon-gc-feed-play-full;
  }
}

.mip-icon-monetization-on {
  &:before {
    content: $mip-icon-monetization-on;
  }
}

.mip-icon-gc-feed-play {
  &:before {
    content: $mip-icon-gc-feed-play;
  }
}

.mip-icon-unpin {
  &:before {
    content: $mip-icon-unpin;
  }
}

.mip-icon-gc-reactions-outline {
  &:before {
    content: $mip-icon-gc-reactions-outline;
  }
}

.mip-icon-feedPlus {
  &:before {
    content: $mip-icon-feedPlus;
  }
}

.mip-icon-gc-challenge-alt-21 {
  &:before {
    content: $mip-icon-gc-challenge-alt-21;
  }
}

.mip-icon-gc-duet1 {
  &:before {
    content: $mip-icon-gc-duet1;
  }
}

.mip-icon-gc-feed-comments {
  &:before {
    content: $mip-icon-gc-feed-comments;
  }
}

.mip-icon-gc-feed-share {
  &:before {
    content: $mip-icon-gc-feed-share;
  }
}

.mip-icon-gc-chosen .path1 {
  &:before {
    content: $mip-icon-gc-chosen-path1;
    color: rgb(0, 0, 0);
  }
}

.mip-icon-gc-chosen .path2 {
  &:before {
    content: $mip-icon-gc-chosen-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.mip-icon-gc-verified-account .path1 {
  &:before {
    content: $mip-icon-gc-verified-account-path1;
    color: rgb(0, 0, 0);
  }
}

.mip-icon-gc-verified-account .path2 {
  &:before {
    content: $mip-icon-gc-verified-account-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.mip-icon-gc-ambassadors-colored .path1 {
  &:before {
    content: $mip-icon-gc-ambassadors-colored-path1;
    color: rgb(0, 0, 0);
  }
}

.mip-icon-gc-ambassadors-colored .path2 {
  &:before {
    content: $mip-icon-gc-ambassadors-colored-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.mip-icon-gc-feed-chat {
  &:before {
    content: $mip-icon-gc-feed-chat;
  }
}

.mip-icon-photo-camera1 {
  &:before {
    content: $mip-icon-photo-camera1;
  }
}

.mip-icon-more {
  &:before {
    content: $mip-icon-more;
  }
}

.mip-icon-share2 {
  &:before {
    content: $mip-icon-share2;
  }
}

.mip-icon-comments1 {
  &:before {
    content: $mip-icon-comments1;
  }
}

.mip-icon-feed {
  &:before {
    content: $mip-icon-feed;
  }
}

.mip-icon-feed_fill {
  &:before {
    content: $mip-icon-feed_fill;
  }
}

.mip-icon-notifications {
  &:before {
    content: $mip-icon-notifications;
  }
}

.mip-icon-notifications_fill {
  &:before {
    content: $mip-icon-notifications_fill;
  }
}

.mip-icon-profile {
  &:before {
    content: $mip-icon-profile;
  }
}

.mip-icon-profile_fill {
  &:before {
    content: $mip-icon-profile_fill;
  }
}

.mip-icon-wallet-gc {
  &:before {
    content: $mip-icon-wallet-gc;
  }
}

.mip-icon-gotcoin {
  &:before {
    content: $mip-icon-gotcoin;
  }
}

.mip-icon-timer {
  &:before {
    content: $mip-icon-timer;
  }
}

.mip-icon-post-add {
  &:before {
    content: $mip-icon-post-add;
  }
}

.mip-icon-chosen {
  &:before {
    content: $mip-icon-chosen;
  }
}

.mip-icon-chosen-plus {
  &:before {
    content: $mip-icon-chosen-plus;
  }
}

.mip-icon-gotchosen-coupons {
  &:before {
    content: $mip-icon-gotchosen-coupons;
  }
}

.mip-icon-cover {
  &:before {
    content: $mip-icon-cover;
  }
}

.mip-icon-new_message {
  &:before {
    content: $mip-icon-new_message;
    color: #80b750;
  }
}

.mip-icon-attention_message .path1 {
  &:before {
    content: $mip-icon-attention_message-path1;
    color: rgb(255, 192, 51);
  }
}

.mip-icon-attention_message .path2 {
  &:before {
    content: $mip-icon-attention_message-path2;
    margin-left: -1.0693359375em;
    color: rgb(255, 255, 255);
  }
}

.mip-icon-attention_message .path3 {
  &:before {
    content: $mip-icon-attention_message-path3;
    margin-left: -1.0693359375em;
    color: rgb(255, 255, 255);
  }
}

.mip-icon-block_message .path1 {
  &:before {
    content: $mip-icon-block_message-path1;
    color: rgb(208, 82, 65);
  }
}

.mip-icon-block_message .path2 {
  &:before {
    content: $mip-icon-block_message-path2;
    margin-left: -1.0009765625em;
    color: rgb(254, 253, 253);
  }
}

.mip-icon-block_message .path3 {
  &:before {
    content: $mip-icon-block_message-path3;
    margin-left: -1.0009765625em;
    color: rgb(254, 253, 253);
  }
}

.mip-icon-block_message .path4 {
  &:before {
    content: $mip-icon-block_message-path4;
    margin-left: -1.0009765625em;
    color: rgb(254, 253, 253);
  }
}

.mip-icon-block_message .path5 {
  &:before {
    content: $mip-icon-block_message-path5;
    margin-left: -1.0009765625em;
    color: rgb(254, 253, 253);
  }
}

.mip-icon-block_message .path6 {
  &:before {
    content: $mip-icon-block_message-path6;
    margin-left: -1.0009765625em;
    color: rgb(254, 254, 254);
  }
}

.mip-icon-error_message .path1 {
  &:before {
    content: $mip-icon-error_message-path1;
    color: rgb(209, 82, 65);
  }
}

.mip-icon-error_message .path2 {
  &:before {
    content: $mip-icon-error_message-path2;
    margin-left: -1.0009765625em;
    color: rgb(255, 255, 255);
  }
}

.mip-icon-error_message .path3 {
  &:before {
    content: $mip-icon-error_message-path3;
    margin-left: -1.0009765625em;
    color: rgb(255, 255, 255);
  }
}

.mip-icon-retract {
  &:before {
    content: $mip-icon-retract;
  }
}

.mip-icon-expand {
  &:before {
    content: $mip-icon-expand;
  }
}

.mip-icon-sequence-theme-light {
  &:before {
    content: $mip-icon-sequence-theme-light;
  }
}

.mip-icon-single-theme-light {
  &:before {
    content: $mip-icon-single-theme-light;
  }
}

.mip-icon-challenge-theme-light {
  &:before {
    content: $mip-icon-challenge-theme-light;
  }
}

.mip-icon-mirror-theme-light {
  &:before {
    content: $mip-icon-mirror-theme-light;
  }
}

.mip-icon-photo-theme-light {
  &:before {
    content: $mip-icon-photo-theme-light;
  }
}

.mip-icon-single-theme-bold {
  &:before {
    content: $mip-icon-single-theme-bold;
  }
}

.mip-icon-sequence-theme-bold {
  &:before {
    content: $mip-icon-sequence-theme-bold;
  }
}

.mip-icon-mirror-theme-bold {
  &:before {
    content: $mip-icon-mirror-theme-bold;
  }
}

.mip-icon-challenge-theme-bold {
  &:before {
    content: $mip-icon-challenge-theme-bold;
  }
}

.mip-icon-photo-theme-bold {
  &:before {
    content: $mip-icon-photo-theme-bold;
  }
}

.mip-icon-dash_menuclosed {
  &:before {
    content: $mip-icon-dash_menuclosed;
  }
}

.mip-icon-dash_home {
  &:before {
    content: $mip-icon-dash_home;
  }
}

.mip-icon-dash_bonus {
  &:before {
    content: $mip-icon-dash_bonus;
  }
}

.mip-icon-dash_settings {
  &:before {
    content: $mip-icon-dash_settings;
  }
}

.mip-icon-dash_support {
  &:before {
    content: $mip-icon-dash_support;
  }
}

.mip-icon-dash_performance {
  &:before {
    content: $mip-icon-dash_performance;
  }
}

.mip-icon-dash_payments {
  &:before {
    content: $mip-icon-dash_payments;
  }
}

.mip-icon-dash_off {
  &:before {
    content: $mip-icon-dash_off;
  }
}

.mip-icon-dash_menuopen {
  &:before {
    content: $mip-icon-dash_menuopen;
  }
}

.mip-icon-dash_offmenu {
  &:before {
    content: $mip-icon-dash_offmenu;
  }
}

.mip-icon-text-tool-select {
  &:before {
    content: $mip-icon-text-tool-select;
  }
}

.mip-icon-text-tool {
  &:before {
    content: $mip-icon-text-tool;
  }
}

.mip-icon-background-tool {
  &:before {
    content: $mip-icon-background-tool;
  }
}

.mip-icon-background-tool-select {
  &:before {
    content: $mip-icon-background-tool-select;
  }
}

.mip-icon-timer_invite {
  &:before {
    content: $mip-icon-timer_invite;
  }
}

.mip-icon-search {
  &:before {
    content: $mip-icon-search;
  }
}

.mip-icon-chat {
  &:before {
    content: $mip-icon-chat;
  }
}

.mip-icon-dash-faq {
  &:before {
    content: $mip-icon-dash-faq;
  }
}

.mip-icon-dash_faq {
  &:before {
    content: $mip-icon-dash_faq;
  }
}

.mip-icon-at-symbol {
  &:before {
    content: $mip-icon-at-symbol;
  }
}

.mip-icon-logout {
  &:before {
    content: $mip-icon-logout;
  }
}

.mip-icon-paperclip {
  &:before {
    content: $mip-icon-paperclip;
  }
}

.mip-icon-share {
  &:before {
    content: $mip-icon-share;
  }
}

.mip-icon-warning {
  &:before {
    content: $mip-icon-warning;
  }
}

.mip-icon-video-camera {
  &:before {
    content: $mip-icon-video-camera;
  }
}

.mip-icon-photo-camera {
  &:before {
    content: $mip-icon-photo-camera;
  }
}

.mip-icon-lock {
  &:before {
    content: $mip-icon-lock;
  }
}

.mip-icon-text {
  &:before {
    content: $mip-icon-text;
  }
}

.mip-icon-gif {
  &:before {
    content: $mip-icon-gif;
  }
}

.mip-icon-sticker {
  &:before {
    content: $mip-icon-sticker;
  }
}

.mip-icon-dollar-circle {
  &:before {
    content: $mip-icon-dollar-circle;
  }
}

.mip-icon-chevron-left-small {
  &:before {
    content: $mip-icon-chevron-left-small;
  }
}

.mip-icon-chevron-right-small {
  &:before {
    content: $mip-icon-chevron-right-small;
  }
}

.mip-icon-chevron-up-small {
  &:before {
    content: $mip-icon-chevron-up-small;
  }
}

.mip-icon-chevron-down-small {
  &:before {
    content: $mip-icon-chevron-down-small;
  }
}

.mip-icon-flip {
  &:before {
    content: $mip-icon-flip;
  }
}

.mip-icon-cut {
  &:before {
    content: $mip-icon-cut;
  }
}

.mip-icon-effects {
  &:before {
    content: $mip-icon-effects;
  }
}

.mip-icon-gear {
  &:before {
    content: $mip-icon-gear;
  }
}

.mip-icon-equalizer {
  &:before {
    content: $mip-icon-equalizer;
  }
}

.mip-icon-code {
  &:before {
    content: $mip-icon-code;
  }
}

.mip-icon-network {
  &:before {
    content: $mip-icon-network;
  }
}

.mip-icon-heart2 {
  &:before {
    content: $mip-icon-heart2;
  }
}

.mip-icon-star {
  &:before {
    content: $mip-icon-star;
  }
}

.mip-icon-bell {
  &:before {
    content: $mip-icon-bell;
  }
}

.mip-icon-bell2 {
  &:before {
    content: $mip-icon-bell2;
  }
}

.mip-icon-paperclip1 {
  &:before {
    content: $mip-icon-paperclip1;
  }
}

.mip-icon-notification {
  &:before {
    content: $mip-icon-notification;
  }
}

.mip-icon-direct {
  &:before {
    content: $mip-icon-direct;
  }
}

.mip-icon-claquete {
  &:before {
    content: $mip-icon-claquete;
  }
}

.mip-icon-shining-star {
  &:before {
    content: $mip-icon-shining-star;
  }
}

.mip-icon-heart {
  &:before {
    content: $mip-icon-heart;
  }
}

.mip-icon-trophy {
  &:before {
    content: $mip-icon-trophy;
  }
}

.mip-icon-reactions {
  &:before {
    content: $mip-icon-reactions;
  }
}

.mip-icon-calendar {
  &:before {
    content: $mip-icon-calendar;
  }
}

.mip-icon-money {
  &:before {
    content: $mip-icon-money;
  }
}

.mip-icon-refresh {
  &:before {
    content: $mip-icon-refresh;
  }
}

.mip-icon-picture {
  &:before {
    content: $mip-icon-picture;
  }
}

.mip-icon-message {
  &:before {
    content: $mip-icon-message;
  }
}

.mip-icon-ellipsis {
  &:before {
    content: $mip-icon-ellipsis;
  }
}

.mip-icon-times {
  &:before {
    content: $mip-icon-times;
  }
}

.mip-icon-chevron-left {
  &:before {
    content: $mip-icon-chevron-left;
  }
}

.mip-icon-chevron-right {
  &:before {
    content: $mip-icon-chevron-right;
  }
}

.mip-icon-chevron-up {
  &:before {
    content: $mip-icon-chevron-up;
  }
}

.mip-icon-chevron-down {
  &:before {
    content: $mip-icon-chevron-down;
  }
}

.mip-icon-play {
  &:before {
    content: $mip-icon-play;
  }
}

.mip-icon-pause {
  &:before {
    content: $mip-icon-pause;
  }
}

.mip-icon-home {
  &:before {
    content: $mip-icon-home;
  }
}

.mip-icon-new-content {
  &:before {
    content: $mip-icon-new-content;
  }
}

.mip-icon-user {
  &:before {
    content: $mip-icon-user;
  }
}

.mip-icon-add-user {
  &:before {
    content: $mip-icon-add-user;
  }
}

.mip-icon-users {
  &:before {
    content: $mip-icon-users;
  }
}

.mip-icon-timer1 {
  &:before {
    content: $mip-icon-timer1;
  }
}

.mip-icon-share1 {
  &:before {
    content: $mip-icon-share1;
  }
}

.mip-icon-rollback {
  &:before {
    content: $mip-icon-rollback;
  }
}

.mip-icon-download {
  &:before {
    content: $mip-icon-download;
  }
}

.mip-icon-flip-camera {
  &:before {
    content: $mip-icon-flip-camera;
  }
}

.mip-icon-trash {
  &:before {
    content: $mip-icon-trash;
  }
}

.mip-icon-at-symbol-bold {
  &:before {
    content: $mip-icon-at-symbol-bold;
  }
}

.mip-icon-upload-bold {
  &:before {
    content: $mip-icon-upload-bold;
  }
}

.mip-icon-network-bold {
  &:before {
    content: $mip-icon-network-bold;
  }
}

.mip-icon-plus-bold {
  &:before {
    content: $mip-icon-plus-bold;
  }
}

.mip-icon-minus-bold {
  &:before {
    content: $mip-icon-minus-bold;
  }
}

.mip-icon-refresh-bold {
  &:before {
    content: $mip-icon-refresh-bold;
  }
}

.mip-icon-chevron-left-bold {
  &:before {
    content: $mip-icon-chevron-left-bold;
  }
}

.mip-icon-chevron-right-bold {
  &:before {
    content: $mip-icon-chevron-right-bold;
  }
}

.mip-icon-chevron-up-bold {
  &:before {
    content: $mip-icon-chevron-up-bold;
  }
}

.mip-icon-chevron-down-bold {
  &:before {
    content: $mip-icon-chevron-down-bold;
  }
}

.mip-icon-comments-solid {
  &:before {
    content: $mip-icon-comments-solid;
  }
}

.mip-icon-video-camera-solid {
  &:before {
    content: $mip-icon-video-camera-solid;
  }
}

.mip-icon-paperclip-solid {
  &:before {
    content: $mip-icon-paperclip-solid;
  }
}

.mip-icon-warning-solid {
  &:before {
    content: $mip-icon-warning-solid;
  }
}

.mip-icon-step-1-solid {
  &:before {
    content: $mip-icon-step-1-solid;
  }
}

.mip-icon-step-2-solid {
  &:before {
    content: $mip-icon-step-2-solid;
  }
}

.mip-icon-step-3-solid {
  &:before {
    content: $mip-icon-step-3-solid;
  }
}

.mip-icon-gear-solid {
  &:before {
    content: $mip-icon-gear-solid;
  }
}

.mip-icon-equalizer-solid {
  &:before {
    content: $mip-icon-equalizer-solid;
  }
}

.mip-icon-heart2-solid {
  &:before {
    content: $mip-icon-heart2-solid;
  }
}

.mip-icon-star-solid {
  &:before {
    content: $mip-icon-star-solid;
  }
}

.mip-icon-bell-solid {
  &:before {
    content: $mip-icon-bell-solid;
  }
}

.mip-icon-bell2-solid {
  &:before {
    content: $mip-icon-bell2-solid;
  }
}

.mip-icon-trophy-solid {
  &:before {
    content: $mip-icon-trophy-solid;
  }
}

.mip-icon-times-solid1 {
  &:before {
    content: $mip-icon-times-solid1;
  }
}

.mip-icon-play-solid {
  &:before {
    content: $mip-icon-play-solid;
  }
}

.mip-icon-pause-solid {
  &:before {
    content: $mip-icon-pause-solid;
  }
}

.mip-icon-url {
  &:before {
    content: $mip-icon-url;
  }
}

.mip-icon-edit {
  &:before {
    content: $mip-icon-edit;
  }
}

.mip-icon-check {
  &:before {
    content: $mip-icon-check;
  }
}

.mip-icon-menu {
  &:before {
    content: $mip-icon-menu;
  }
}

.mip-icon-trash-bold {
  &:before {
    content: $mip-icon-trash-bold;
  }
}

.mip-icon-pause-bold {
  &:before {
    content: $mip-icon-pause-bold;
  }
}

.mip-icon-play-bold {
  &:before {
    content: $mip-icon-play-bold;
  }
}

.mip-icon-share-solid {
  &:before {
    content: $mip-icon-share-solid;
  }
}

.mip-icon-volume-on-solid {
  &:before {
    content: $mip-icon-volume-on-solid;
  }
}

.mip-icon-volume-off-solid {
  &:before {
    content: $mip-icon-volume-off-solid;
  }
}

.mip-icon-tag-solid {
  &:before {
    content: $mip-icon-tag-solid;
  }
}

.mip-icon-notification-solid {
  &:before {
    content: $mip-icon-notification-solid;
  }
}

.mip-icon-flash-solid {
  &:before {
    content: $mip-icon-flash-solid;
  }
}

.mip-icon-flash-a-solid {
  &:before {
    content: $mip-icon-flash-a-solid;
  }
}

.mip-icon-flash-crossed-solid {
  &:before {
    content: $mip-icon-flash-crossed-solid;
  }
}

.mip-icon-new-content-solid {
  &:before {
    content: $mip-icon-new-content-solid;
  }
}

.mip-icon-user-solid {
  &:before {
    content: $mip-icon-user-solid;
  }
}

.mip-icon-mobile-devices {
  &:before {
    content: $mip-icon-mobile-devices;
  }
}

.mip-icon-share-gc {
  &:before {
    content: $mip-icon-share-gc;
  }
}

.mip-icon-tiktok {
  &:before {
    content: $mip-icon-tiktok;
  }
}

.mip-icon-titok-rounded {
  &:before {
    content: $mip-icon-titok-rounded;
  }
}

.mip-icon-instagram-rounded {
  &:before {
    content: $mip-icon-instagram-rounded;
  }
}

.mip-icon-facebook-rounded {
  &:before {
    content: $mip-icon-facebook-rounded;
  }
}

.mip-icon-twitter-rounded {
  &:before {
    content: $mip-icon-twitter-rounded;
  }
}

.mip-icon-gc-hand {
  &:before {
    content: $mip-icon-gc-hand;
  }
}

.mip-icon-gc-hand-circle {
  &:before {
    content: $mip-icon-gc-hand-circle;
  }
}

.mip-icon-gdpr {
  &:before {
    content: $mip-icon-gdpr;
  }
}

.mip-icon-facebook {
  &:before {
    content: $mip-icon-facebook;
  }
}

.mip-icon-instagram {
  &:before {
    content: $mip-icon-instagram;
  }
}

.mip-icon-twitter {
  &:before {
    content: $mip-icon-twitter;
  }
}

.mip-icon-youtube {
  &:before {
    content: $mip-icon-youtube;
  }
}

.mip-icon-arrow-right {
  &:before {
    content: $mip-icon-arrow-right;
  }
}

.mip-icon-arrow-left {
  &:before {
    content: $mip-icon-arrow-left;
  }
}

.mip-icon-arrow-down {
  &:before {
    content: $mip-icon-arrow-down;
  }
}

.mip-icon-arrow-up {
  &:before {
    content: $mip-icon-arrow-up;
  }
}

.mip-icon-attachment {
  &:before {
    content: $mip-icon-attachment;
  }
}

.mip-icon-sound-mix {
  &:before {
    content: $mip-icon-sound-mix;
  }
}

.mip-icon-location-bold {
  &:before {
    content: $mip-icon-location-bold;
  }
}

.mip-icon-globe-bold {
  &:before {
    content: $mip-icon-globe-bold;
  }
}

.mip-icon-ellipsis-bold {
  &:before {
    content: $mip-icon-ellipsis-bold;
  }
}

.mip-icon-reactions-bold {
  &:before {
    content: $mip-icon-reactions-bold;
  }
}

.mip-icon-capitalize-bold {
  &:before {
    content: $mip-icon-capitalize-bold;
  }
}

.mip-icon-download-bold {
  &:before {
    content: $mip-icon-download-bold;
  }
}

.mip-icon-rollback-bold {
  &:before {
    content: $mip-icon-rollback-bold;
  }
}

.mip-icon-check-bold {
  &:before {
    content: $mip-icon-check-bold;
  }
}

.mip-icon-dollar-bold {
  &:before {
    content: $mip-icon-dollar-bold;
  }
}

.mip-icon-send-solid {
  &:before {
    content: $mip-icon-send-solid;
  }
}

.mip-icon-triangle-down-solid {
  &:before {
    content: $mip-icon-triangle-down-solid;
  }
}

.mip-icon-triangle-left-solid {
  &:before {
    content: $mip-icon-triangle-left-solid;
  }
}

.mip-icon-triangle-right-solid {
  &:before {
    content: $mip-icon-triangle-right-solid;
  }
}

.mip-icon-triangle-up-solid {
  &:before {
    content: $mip-icon-triangle-up-solid;
  }
}

.mip-icon-volume-mute {
  &:before {
    content: $mip-icon-volume-mute;
  }
}

.mip-icon-volume {
  &:before {
    content: $mip-icon-volume;
  }
}

.mip-icon-audio {
  &:before {
    content: $mip-icon-audio;
  }
}

.mip-icon-player {
  &:before {
    content: $mip-icon-player;
  }
}

.mip-icon-volume-medium {
  &:before {
    content: $mip-icon-volume-medium;
  }
}

.mip-icon-volume1 {
  &:before {
    content: $mip-icon-volume1;
  }
}

.mip-icon-audio1 {
  &:before {
    content: $mip-icon-audio1;
  }
}

.mip-icon-speaker {
  &:before {
    content: $mip-icon-speaker;
  }
}

.mip-icon-player1 {
  &:before {
    content: $mip-icon-player1;
  }
}

.mip-icon-tiktok-button {
  &:before {
    content: $mip-icon-tiktok-button;
  }
}

.mip-icon-twitter-button {
  &:before {
    content: $mip-icon-twitter-button;
  }
}

.mip-icon-facebook-button {
  &:before {
    content: $mip-icon-facebook-button;
  }
}

.mip-icon-youtube-button {
  &:before {
    content: $mip-icon-youtube-button;
  }
}

.mip-icon-instagram-button {
  &:before {
    content: $mip-icon-instagram-button;
  }
}

.mip-icon-apple {
  &:before {
    content: $mip-icon-apple;
  }
}

.mip-icon-google {
  &:before {
    content: $mip-icon-google;
  }
}

.mip-icon-whatsapp {
  &:before {
    content: $mip-icon-whatsapp;
  }
}

.mip-icon-hashtag {
  &:before {
    content: $mip-icon-hashtag;
  }
}

.mip-icon-carnival {
  &:before {
    content: $mip-icon-carnival;
  }
}

.mip-icon-tutorials {
  &:before {
    content: $mip-icon-tutorials;
  }
}

.mip-icon-comments {
  &:before {
    content: $mip-icon-comments;
  }
}

.mip-icon-mail {
  &:before {
    content: $mip-icon-mail;
  }
}

.mip-icon-loading {
  &:before {
    content: $mip-icon-loading;
  }
}

.mip-icon-plus {
  &:before {
    content: $mip-icon-plus;
  }
}

.mip-icon-following-bold {
  &:before {
    content: $mip-icon-following-bold;
  }
}

.mip-icon-hashtag-bold {
  &:before {
    content: $mip-icon-hashtag-bold;
  }
}

.mip-icon-menu-bold {
  &:before {
    content: $mip-icon-menu-bold;
  }
}

.mip-icon-search-bold {
  &:before {
    content: $mip-icon-search-bold;
  }
}

.mip-icon-tutorials-bold {
  &:before {
    content: $mip-icon-tutorials-bold;
  }
}

.mip-icon-home-bold {
  &:before {
    content: $mip-icon-home-bold;
  }
}

.mip-icon-profile-bold {
  &:before {
    content: $mip-icon-profile-bold;
  }
}

.mip-icon-dash-faq-bold {
  &:before {
    content: $mip-icon-dash-faq-bold;
  }
}

.mip-icon-gc-hand-circle-bold {
  &:before {
    content: $mip-icon-gc-hand-circle-bold;
  }
}

.mip-icon-carnival-bold {
  &:before {
    content: $mip-icon-carnival-bold;
  }
}

.mip-icon-ondemand-bold {
  &:before {
    content: $mip-icon-ondemand-bold;
  }
}

.mip-icon-post-bold {
  &:before {
    content: $mip-icon-post-bold;
  }
}

.mip-icon-timer-bold {
  &:before {
    content: $mip-icon-timer-bold;
  }
}

.mip-icon-gotcoin-bold {
  &:before {
    content: $mip-icon-gotcoin-bold;
  }
}

.mip-icon-arrow-right-bold {
  &:before {
    content: $mip-icon-arrow-right-bold;
  }
}

.mip-icon-arrow-left-bold {
  &:before {
    content: $mip-icon-arrow-left-bold;
  }
}

.mip-icon-arrow-down-bold {
  &:before {
    content: $mip-icon-arrow-down-bold;
  }
}

.mip-icon-arrow-up-bold {
  &:before {
    content: $mip-icon-arrow-up-bold;
  }
}

.mip-icon-block-bold {
  &:before {
    content: $mip-icon-block-bold;
  }
}

.mip-icon-link-bold {
  &:before {
    content: $mip-icon-link-bold;
  }
}

.mip-icon-carnival-solid {
  &:before {
    content: $mip-icon-carnival-solid;
  }
}

.mip-icon-gc-coupon-solid {
  &:before {
    content: $mip-icon-gc-coupon-solid;
  }
}

.mip-icon-article-solid {
  &:before {
    content: $mip-icon-article-solid;
  }
}

.mip-icon-gc-hand-circle-solid {
  &:before {
    content: $mip-icon-gc-hand-circle-solid;
  }
}

.mip-icon-photo-camera-solid {
  &:before {
    content: $mip-icon-photo-camera-solid;
  }
}

.mip-icon-phone-solid {
  &:before {
    content: $mip-icon-phone-solid;
  }
}

.mip-icon-volume-on2-solid {
  &:before {
    content: $mip-icon-volume-on2-solid;
  }
}

.mip-icon-volume-off2-solid {
  &:before {
    content: $mip-icon-volume-off2-solid;
  }
}

.mip-icon-eye-solid {
  &:before {
    content: $mip-icon-eye-solid;
  }
}

.mip-icon-smiley-face {
  &:before {
    content: $mip-icon-smiley-face;
  }
}

.mip-icon-happy-face {
  &:before {
    content: $mip-icon-happy-face;
  }
}

.mip-icon-loved-face {
  &:before {
    content: $mip-icon-loved-face;
  }
}

.mip-icon-laughing-face {
  &:before {
    content: $mip-icon-laughing-face;
  }
}

.mip-icon-surprised-face {
  &:before {
    content: $mip-icon-surprised-face;
  }
}

.mip-icon-sleeping-face {
  &:before {
    content: $mip-icon-sleeping-face;
  }
}

.mip-icon-crying-face {
  &:before {
    content: $mip-icon-crying-face;
  }
}

.mip-icon-angry-face {
  &:before {
    content: $mip-icon-angry-face;
  }
}

.mip-icon-smiley-face-circle {
  &:before {
    content: $mip-icon-smiley-face-circle;
  }
}

.mip-icon-happy-face-circle {
  &:before {
    content: $mip-icon-happy-face-circle;
  }
}

.mip-icon-loved-face-circle {
  &:before {
    content: $mip-icon-loved-face-circle;
  }
}

.mip-icon-laughing-face-circle {
  &:before {
    content: $mip-icon-laughing-face-circle;
  }
}

.mip-icon-surprised-face-circle {
  &:before {
    content: $mip-icon-surprised-face-circle;
  }
}

.mip-icon-sleeping-face-circle {
  &:before {
    content: $mip-icon-sleeping-face-circle;
  }
}

.mip-icon-crying-face-circle {
  &:before {
    content: $mip-icon-crying-face-circle;
  }
}

.mip-icon-angry-face-circle {
  &:before {
    content: $mip-icon-angry-face-circle;
  }
}

.mip-icon-envelope-o {
  &:before {
    content: $mip-icon-envelope-o;
  }
}

.mip-icon-phone-play-bold {
  &:before {
    content: $mip-icon-phone-play-bold;
  }
}

.mip-icon-monitor-ad-bold {
  &:before {
    content: $mip-icon-monitor-ad-bold;
  }
}

.mip-icon-following-solid {
  &:before {
    content: $mip-icon-following-solid;
  }
}

.mip-icon-tutorials-solid {
  &:before {
    content: $mip-icon-tutorials-solid;
  }
}

.mip-icon-dash-faq-solid {
  &:before {
    content: $mip-icon-dash-faq-solid;
  }
}

.mip-icon-home-solid {
  &:before {
    content: $mip-icon-home-solid;
  }
}

.mip-icon-profile-solid {
  &:before {
    content: $mip-icon-profile-solid;
  }
}

.mip-icon-check-solid {
  &:before {
    content: $mip-icon-check-solid;
  }
}

.mip-icon-clock-solid {
  &:before {
    content: $mip-icon-clock-solid;
  }
}

.mip-icon-hashtag-solid {
  &:before {
    content: $mip-icon-hashtag-solid;
  }
}

.mip-icon-times-solid {
  &:before {
    content: $mip-icon-times-solid;
  }
}

.mip-icon-trash-solid {
  &:before {
    content: $mip-icon-trash-solid;
  }
}

